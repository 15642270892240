/* You can add global styles to this file, and also import other style files */
@import '~roboto-fontface/css/roboto/roboto-fontface.css';

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

app-root {
  display: flex;
  flex-direction: column;
}

* {
  font-family: "Roboto-Regular", sans-serif;
}

a {
  &:visited {
    color: rgb(0, 90, 160);
  }
}

.mat-progress-spinner {
  margin: 32px auto;

  svg {
    circle {
      stroke: #3C8BCF !important;
      stroke-width: 6px !important;
    }
  }
}


select {
  background: linear-gradient(-180deg, rgb(246, 245, 246) 0%, rgb(232, 232, 232) 100%);
  border-radius: 4px;
  border: 1px solid rgb(178, 178, 178);
  height: 36px;


  option {
    max-width: 200px;
  }
}

mat-card {
  box-shadow: 0 2px 6px 0 rgba(0, 0, 0, 0.14),
  0 1px 2px 0 rgba(0, 0, 0, 0.08),
  0 0 1px 0 rgba(0, 0, 0, 0.06),
  0 0 0 0 rgba(0, 0, 0, 0.04) !important;
}

.backdrop-background {
  background-color: rgba(0, 0, 0, 0.6);
}

mat-dialog-container {
  padding: 32px !important;
}

/**
 * Slide toggle
 * ---
 * CSS variables override for the material slide toggle component.
 */
html body .mat-mdc-slide-toggle {
  --ts-blue: rgb(0 116 204);
  --ts-blue-light: rgb(0 116 204 / .25);
  --ts-blue-dark: rgb(0 101 179);

  --mdc-switch-disabled-selected-handle-color: var(--ts-blue);
  --mdc-switch-disabled-unselected-handle-color: var(--ts-blue);
  --mdc-switch-disabled-selected-track-color: var(--ts-blue);
  --mdc-switch-disabled-unselected-track-color: var(--ts-blue);
  --mdc-switch-unselected-focus-state-layer-color: var(--ts-blue);
  --mdc-switch-unselected-pressed-state-layer-color: var(--ts-blue);
  --mdc-switch-unselected-hover-state-layer-color: var(--ts-blue);
  --mdc-switch-unselected-focus-track-color: var(--ts-blue-light);
  --mdc-switch-unselected-hover-track-color: var(--ts-blue-light);
  --mdc-switch-unselected-pressed-track-color: var(--ts-blue-light);
  --mdc-switch-unselected-track-color: var(--ts-blue-light);
  --mdc-switch-unselected-focus-handle-color: var(--ts-blue-dark);
  --mdc-switch-unselected-hover-handle-color: var(--ts-blue-dark);
  --mdc-switch-unselected-pressed-handle-color: var(--ts-blue-dark);
  --mdc-switch-handle-surface-color: var(--ts-blue);
  --mdc-switch-unselected-handle-color: var(--ts-blue);
  --mdc-switch-selected-icon-color: #fff;
  --mdc-switch-disabled-selected-icon-color: #fff;
  --mdc-switch-disabled-unselected-icon-color: #fff;
  --mdc-switch-unselected-icon-color: #fff;

  &.mat-accent {
    --ts-green: rgb(65 164 82);
    --ts-green-light: rgb(65 164 82 / .25);
    --ts-green-dark: rgb(58 146 73);

    --mdc-switch-selected-focus-state-layer-color: var(--ts-green);
    --mdc-switch-selected-handle-color: var(--ts-green);
    --mdc-switch-selected-hover-state-layer-color: var(--ts-green);
    --mdc-switch-selected-pressed-state-layer-color: var(--ts-green);
    --mdc-switch-selected-focus-handle-color: var(--ts-green-dark);
    --mdc-switch-selected-hover-handle-color: var(--ts-green-dark);
    --mdc-switch-selected-pressed-handle-color: var(--ts-green-dark);
    --mdc-switch-selected-focus-track-color: var(--ts-green-light);
    --mdc-switch-selected-hover-track-color: var(--ts-green-light);
    --mdc-switch-selected-pressed-track-color: var(--ts-green-light);
    --mdc-switch-selected-track-color: var(--ts-green-light);
  }
}
